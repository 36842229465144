import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/recipe4.css'

import $ from 'jquery' // important: case sensitive.
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image

import Layout from "../components/layout"
import SEO from "../components/seo"
import StopMotion from "../images/StopMotion_PapelotedeBacalhau.mp4"
import cardfoto1 from "../images/steak-card.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/nacos-card.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/Bifemanteiga-card.jpg" // Tell webpack this JS file uses this image
import cardfoto4 from "../images/PapelotedeBacalhau.jpg" // Tell webpack this JS file uses this image
import iconnutri from "../images/icon-nutri.png" // Tell webpack this JS file uses this image


const ReceitaD = () => (
  <Layout>
    <SEO title="Joe's Butcher - Receita 2" />

    <div id="rec4-section-1">
        <div className="wrapper-foto">
    </div>

        <div className="wrapper">
        <div className="titulo aller-display">
            Steak Sandwich com Cebola Caramelizada e Cheddar
        </div>
        <div className="desc sneak-regular">
        Uma deliciosa sanduíche artesanal, que é uma explosão de sabores a cada dentada.        </div>
        </div>
    </div>
    <div id="rec4-section-2">
        <div className="wrapper">
            <div className="left-col">
                <div className="card"> 
                <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Ingredientes
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Emb. de Bifes
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4/6 
                        </div>
                        <div className="text sneak-regular">  
                         Fatias de Pão
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 
                        </div>
                        <div className="text sneak-regular">  
                        Cebolas
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            75g
                        </div>
                        <div className="text sneak-regular">  
                         Queijo Ralado
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B
                        </div>
                        <div className="text sneak-regular">  
                         Rúcula Selvagem
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                            Tomate
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Colher de sopa de Mostarda Antiga
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2
                        </div>
                        <div className="text sneak-regular">  
                        Colheres de sopa de Maionese

                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            100g
                        </div>
                        <div className="text sneak-regular">  
                        Açucar
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                        Sal e pimenta
                        </div>
                        
                    </div>
              
                  
                    
                    
                </div>
               
                </div>
            </div>
            <div className="right-col">

            <div className="card">
                    <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Receita
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1 - 
                        </div>
                        <div className="text sneak-regular">  
                       Retire a peça de carne da embalagem 20 minutos antes da confeção e deixe à temperatura ambiente.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 -
                        </div>
                        <div className="text sneak-regular">  
                    Numa frigideira, refogue a cebola com azeite até alourar e adicione o açucar e uma pitada de sal. Misture bem, mexendo a cebola até caramelizar em lume brando. Retire do lume e reserve.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3 - 
                        </div>
                        <div className="text sneak-regular">  
                        Numa taça misture a maionese e a mostarda antiga.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 - 
                        </div>
                        <div className="text sneak-regular">  
                        Adicione os bifes numa frigideira bem quente, tempere com sal e pimenta e frite ao ponto desejado.                  
                          </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            5 - 
                        </div>
                        <div className="text sneak-regular">  
                       Toste um pouco as fatias de pão e coloque o molho, a rúcula, as fatias de tomate previamente cortadas, o bife, o queijo ralado e a cebola quente por cima.                        </div>
                        
                    </div>
       
                  
                 
                    
                </div>
               </div>
            </div>
        </div>
    </div>

    <div id="section-nutri">
    <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                    <div className="icon-nutri">
                    <img src={iconnutri} alt="ingredient" />

                    </div>
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2">
                    <div className="titulo sneak-regular">
                           942,1
                        </div>
                        <div className="titulo sneak-regular">
                            40,7
                        </div>
                        <div className="titulo sneak-regular">
                           14,7
                        </div>
                        <div className="titulo sneak-regular">
                          88,9
                        </div>
                        <div className="titulo sneak-regular">
                         54,9
                        </div>
                        <div className="titulo sneak-regular">
                          55,0
                        </div>
                        <div className="titulo sneak-regular">
                        3,9
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>


  </Layout>
)


export default ReceitaD
